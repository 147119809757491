<template>
    <div>
    <div v-if="crud == 'switchCrud'" class="d-flex mt-2 justify-content-between">
                <span :class="bold ? 'text-black' : ''" :style="bold ? 'font-weight:700;background-color:#ffef0075' : ''">{{ title }}</span>
                <div class="d-flex">
                <b-form-checkbox
                  v-model="value['create']"
                  :checked="value['create']"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left pad1">
                    C
                  </span>
                  <span class="switch-icon-right pad1">
                    C
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="value['read']"
                  :checked="value['read']"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left pad1">
                    R
                  </span>
                  <span class="switch-icon-right pad1">
                    R
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="value['update']"
                  :checked="value['update']"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left pad1">
                    U
                  </span>
                  <span class="switch-icon-right pad1">
                    U
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="value['delete']"
                  :checked="value['delete']"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left pad1">
                    D
                  </span>
                  <span class="switch-icon-right pad1">
                    D
                  </span>
                </b-form-checkbox>
                </div>
              </div>
              <div v-else-if="crud == 'checkboxCrud'" class="d-flex mt-2 justify-content-between">
                <span :class="bold ? 'text-black' : ''" :style="bold ? 'font-weight:700;background-color:#ffef0075' : ''">{{ title }}</span>
                <div class="d-flex justify-content-end" style="width:50%">
                <div class="d-flex">
                <div @click="hadelClickLetterCheckBox('create')" :class="this.value['create'] == true ? 'customecheckboxActive' : 'customecheckboxInactive'">
                  C
                </div>
                <div @click="hadelClickLetterCheckBox('read')" :class="this.value['read'] == true ? 'customecheckboxActive' : 'customecheckboxInactive'">
                  R
                </div>
                <div @click="hadelClickLetterCheckBox('update')" :class="this.value['update'] == true ? 'customecheckboxActive' : 'customecheckboxInactive'">
                  U
                </div>
                <div @click="hadelClickLetterCheckBox('delete')" :class="this.value['delete'] == true ? 'customecheckboxActive' : 'customecheckboxInactive'">
                  D
                </div>
                </div>

            </div>
            </div>
              <div v-else-if="crud == 'switch'" class="d-flex mt-2 justify-content-between">
                <span :class="bold ? 'text-black' : ''" :style="bold ? 'font-weight:700;background-color:#ffef0075' : ''">{{ title }}</span>
              <b-form-checkbox
                  v-model="value['read']"
                  :checked="value['read']"
                  class="custom-control-primary pl-1"
                  name="check-button"
                  switch
                >
                </b-form-checkbox>
            </div>
</div>
</template>
<script>
export default {
    props:['item','bold','title','crud','value','disabled'],
    mounted(){
      setTimeout(() => {
        this.value['permission_id'] = this.item
        this.$forceUpdate()
      }, 300);
    },
    methods:{
      hadelClickLetterCheckBox(value){
        this.value[value] = this.value[value]== undefined ? false : this.value[value]
        this.value[value] = this.value[value] == true ? false : true

        this.$emit('customRights',this.item)
        this.$forceUpdate()
      }
    }
}
</script>
<style>
.customecheckboxActive{   
  width: 20px;
    height: 20px;
    background-color: #3f8bfd;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 3px;
    line-height:0px;
    cursor:pointer;
}
.customecheckboxInactive{
    width: 20px;
    height: 20px;
    background-color: #fff;
    color: #9b9fa4;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height:0px;
    border-radius: 5px;
    border: 1px solid #9b9fa4;
    margin-right: 3px;
    cursor:pointer;
}
.pad1{
  padding-top: 2px;
}
</style>
