<template>
    <section id="dashboard-ecommerce">
      <div class="sec1 d-flex justify-content-between pb-1">
        <h1 class="text-black" 
          @click="$router.push(`/roles`)">Roles - {{ $route.params.name }}{{ search }}</h1>
        <div>
          <!-- <button class="btn notofication-btn mr-1">
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
            </button> -->
  
          <button
            type="button"
            class="btn btn-primary"
            style="border-radius: 16px; padding: 16px"
            v-b-modal.modal
            @click="HandelClickNewRole"
          >
            +&nbsp; Create User
          </button>
  
          <b-modal id="modal" size="lg" ref="modal" class="m-2 custom--model">
            <template #modal-header>
            <div style="width:50%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-6">
                <h2>{{edit ? 'Edit' : 'Create'}} User</h2>
              </div>
              <div class="form-group col-md-6">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" 
                  name="check-button" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
                <!-- <input
                v-model="status"
                class="form-control"
                id="inputEmail4"
                placeholder="Select Status"
              /> -->
              </div>
            </div>
            <b-button @click="$refs.modal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
            <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="First Name">
                    <validation-provider #default="{ errors }" name="First Name" rules="required">
                      <b-form-input v-model="firstName" :state="errors.length > 0 ? false : null"
                        placeholder="Enter First Name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Last Name">
                    <validation-provider #default="{ errors }" name="Last Name" rules="required">
                      <b-form-input v-model="lastName" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Last Name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Email">
                    <validation-provider #default="{ errors }" name="Email" rules="required">
                      <b-form-input v-model="email" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Email" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Phone Number">
                    <validation-provider #default="{ errors }" name="Phone Number" rules="required|integer">
                      <b-form-input v-model="phoneNumber" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Phone Number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2 " label="Profile Image URL">
                    <validation-provider #default="{ errors }" name="Profile Image URL" rules="required">
                      <div class="d-flex">
                        <b-form-input v-model="profile_image" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Profile  Image URL" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Gender">
                    <validation-provider #default="{ errors }" name="Gender" rules="required">
                      <v-select v-model="gender" :options="['Male','Female']" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Gender" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group class="mb-2" label="Password">
                    <validation-provider #default="{ errors }" name="Password" rules="required">
                      
                <b-input-group class="input-group-merge">
                      <b-form-input v-model="password" :state="errors.length > 0 ? false : null"
                    :type="passwordFieldTypeNew"
                        placeholder="Enter Password" />
                        <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                
                <div class="col-md-12">
                  <b-form-group class="mb-2" label="Confirm Password">
                    <validation-provider #default="{ errors }" name="Confirm Password" rules="required">
                      
                <b-input-group class="input-group-merge">
                      <b-form-input v-model="cnfPassword" :state="errors.length > 0 ? false : null"
                    :type="passwordFieldTypeNew1"
                        placeholder="Enter Confirm Password" />
                        <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew1"
                      class="cursor-pointer"
                      @click="togglePasswordNew1"
                    />
                  </b-input-group-append>
                </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                </div>
            <div class="modal-footer1">
              <button type="button" @click="$refs.modal.hide()" class="btn btn-default">Cancel</button>
              <button
                type="button"
                class="btn btn-primary mr-1"
                :disabled="invalid"
                @click="submitForm"
                style="border: 1px solid black"
                data-dismiss="modal"
              >
                {{edit ? 'Update' : 'Add'}} 
              </button>
            </div>
            </b-form>
            </validation-observer>
          </b-modal>
        </div>
      </div>
  
          
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <div class="d-flex justify-content-center align-items-center" v-else-if="rolesData.length == 0">
          <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
        </div>
        <div v-else class="drop-zone">
        <draggable
          v-model="rolesData"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list draggable-task-handle d-inline"
        >
          <b-card
            no-body
            class="card-revenue-budget dash-total-card mb-2 drag-el"
            v-for="(item,index) in rolesData"
            :key="index"
          >
            <b-row class="p-1">
              <b-col md="4" class="d-flex align-items-center pl-2">
              <div class="ml-1 mr-2">
                  <b-img v-if="checkURL(item.profile_image)" v-bind="mainProps" rounded="circle" :src="item.profile_image"
                    alt="Circle image" class="d-inline-block" />
                  <b-img v-else v-bind="mainProps" rounded="circle"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block" />
              </div>
                <div>
                  <div class="font-weight-bolder text-black">
                    {{ item.fullname }}
                  </div>
                  <div class="mb-half">{{item.email}}</div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Gender</div>
                  <div class="font-weight-bolder text-black">{{ item.gender }}</div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Phone Number</div>
                  <div class="font-weight-bolder text-black">{{ item.phone_number }}</div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Role</div>
                  <div class="font-weight-bolder text-black">{{ $route.params.name }}</div>
                </div>
              </b-col>
              <b-col md="1" class="d-flex align-items-center">
                <div>
                  <div class="mb-half">Status</div>
                  <div class="font-weight-bolder">
                    <div :class="item.is_active ? 'status-green-btn' : 'status-red-btn'">
                      <strong><span class="boder">{{item.is_active ? 'Active' : 'Inactive'}}</span></strong>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="1"
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  text-black
                "
              >
              <div :id="'popover-button-variant' + item.id" href="#"
                style="background: #f4f9fd; padding: 8px; border-radius: 16px">
                <feather-icon icon="MoreVerticalIcon" size="25" />
              </div>
              </b-col>
            </b-row>
            <b-popover :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover">
            <!-- <button type="button" class="btn notofication-btn ml-1" v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="handelShow(item)" style="background: rgb(125 133 146 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button> -->
            <button type="button" class="btn notofication-btn m-0" @click="handelEdit(item.id)" v-b-modal.modal
              style="background: rgb(63 140 254 / 13%); margin: 8px">
              
              <img src="../../../assets/images/svg/Edit.svg" alt="Snow" />
            </button>
            <button type="button" class="btn notofication-btn m-0" @click="handelDelete(item.id)"
              style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
              <img src="../../../assets/images/client/delete.png" alt="Snow" />
            </button>
          </b-popover>
          </b-card>
        </draggable>
        <div class="no-results" :class="{ show: !rolesData.length }"></div>
      </div>
  
     
      <button v-if="!isLoading && rolesData.length" type="button" class="w-auto  btn slide-btn">
        <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
      </button>
    </section>
  </template>
  
  <script>
  import draggable from "vuedraggable";
  import axios from "axios";
  import CrudComponent from "./CrudComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import vSelect from 'vue-select'
import useJwt from "@/auth/jwt/useJwt";
  export default {
    components: {
      draggable,
      CrudComponent,
    ValidationProvider,
    ValidationObserver,
    vSelect
    },
    data() {
      return {
        baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
        data: {},
        permissionType:"",
        rolesData:[],
        rolesData1:[],
      required, integer,
        permissions:[],
        paginationStart: 0,
        pageNo: 1,
      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
        passwordFieldTypeNew:"password",
        passwordFieldTypeNew1:"password",
        paginationLength:0,
        paginationEnd: 10,
        isLoading:false,
        roleName:"",
        firstName:'',
        lastName:'',
        profile_image:'',
        gender:'',
        email:'',
        status:false,
        phoneNumber:'',
        password:'',
        edit:false,
        cnfPassword:'',
        // rolesData: [
        //   { name: "Super Admin" },
        //   { name: "Admin" },
        //   { name: "Date Entry" },
        //   { name: "Ad Placement / Subscription Manager" },
        //   { name: "Application User" },
        // ],
        value: "1",
      };
    },
    created() {},
    mounted(){
      setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 1000);
      this.getRoles();
    },
  computed: {
    search() {
      // let data = []
      let search = this.$store.state.searchData
      // data = this.rolesData1.filter(item => (item.user_name?.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.id.toString().includes(search) 
      //                                              || item.email.replace(/\s/g,'').toLowerCase().includes(search) 
      //                                              || item.created.replace(/\s/g,'').toLowerCase().includes(search)
      //                                              || (item.is_active == 1 ? 'Active' : 'Inactive').replace(/\s/g,'').toLowerCase().includes(search)
      //                                              ))
      if (this.$store.state.application) {
        this.getSearch(search);
      }
      setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 1000);
  },  
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew1() {
      return this.passwordFieldTypeNew1 === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
    methods: {
      handelEdit(id) {
      this.$http.post(`${this.baseURL}/user-info/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
          this.firstName = this.editItem.firstname
          this.lastName = this.editItem.lastname
          this.email = this.editItem.email
          this.profile_image = this.editItem.profile_image
          this.gender = this.editItem.gender
          this.status = this.editItem.is_active ? true : false,
          this.phoneNumber = this.editItem.phone_number
          this.password = this.editItem.decrypt_password
          this.cnfPassword = this.editItem.decrypt_password
          this.edit = true
        })
    },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/user-info/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getRoles()
          this.$forceUpdate()
        })
    },
      submitForm(){
        
      const formData = new FormData();
      
      formData.append('firstname', this.firstName);
      formData.append('lastname', this.lastName);
      formData.append('fullname', `${this.firstName} ${this.lastName}`);
      formData.append('email', this.email);
      formData.append('profile_image', this.profile_image);
      formData.append('gender', this.gender);
      formData.append('password', this.password);
      formData.append('decrypt_password', this.password);
      formData.append('is_active', this.status ? 1 : 0);
      formData.append('role', this.$route.params.id);
      formData.append('phone_number', this.phoneNumber);
      formData.append('app_id', this.$store.state.application.id);
      if (this.edit) {
      // formData.append('is_url', this.checkURL(this.profile_image));
      formData.append('id', this.editItem.id);
      }
      if(this.password != this.cnfPassword){
        this.$swal({
                title: "Warning!!",
                text: `Password and Confirm Password do not Match`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
      }else{
      this.$refs.submitPrevent.validate().then((success) => {
        if(success){
        this.$http.post(`${this.baseURL}/user-info/${this.edit ? 'update' : 'create'}`, formData,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 201) {
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getRoles()
              this.$refs.modal.hide()
            } else {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
        }
      })
    }
      },
    checkURL(url) {
      if(url){
        const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return pattern.test(typeof url == 'string' ? url.replace(/\s/g,'%20') : url);
      }
      return false
    },
      HandelClickNewRole(){
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.password = ''
        this.status = '' 
        this.profile_image = '' 
        this.gender = '' 
        this.cnfPassword = '',
        this.phoneNumber = ''
        this.edit = false
      },
      togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
      togglePasswordNew1() {
      this.passwordFieldTypeNew1 =
        this.passwordFieldTypeNew1 === "password" ? "text" : "password";
    },
      permissionHandelchange(value){
       this.permissionType = value
      },
      getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/role/rolebyid-search`, { search: search, pageno: this.pageNo, id: parseInt(this.$route.params.id) },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.rolesData = res.data.data
          this.paginationLength = res.data.totaldata
          this.rolesData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/role/rolebyid-search`, { search: search, pageno: this.pageNo, id: parseInt(this.$route.params.id) }).then((res) => {
        this.rolesData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.rolesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/role/rolebyid`, { pageno: this.pageNo, id: parseInt(this.$route.params.id) }).then((res) => {
        this.rolesData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.rolesData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
      async getRoles(){
      this.isLoading=true
        this.$http.post(`${this.baseURL}/role/rolebyid`, { pageno: this.pageNo, id: parseInt(this.$route.params.id) }).then((res) => {
              this.rolesData = res.data.data 
                this.paginationLength = res.data.totaldata
                this.rolesData1 = res.data.data
                this.isLoading = false
          })
      }
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/dashboard-ecommerce.scss";
  @import "@core/scss/vue/libs/chart-apex.scss";
  
  .searchIcon{
    width: 10%
  }
  .searchInput{
    width: 90%
  }
  @media only screen and (max-width: 576px){
  .searchIcon{
    width: 15%
  }
  .searchInput{
    width: 85%
  }
  }
  .pad1{
    padding-top: 2px;
  }
  .manualRadio{
    border: 2px solid #cfd7df;
      width: 30%;
      height: 50px;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .manualradioborder{
  
    border: 2px solid #007bff;
  }
  .status-green-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #0ac947;
    border: 1px solid #0ac947;
    border-radius: 4px;
  }
  .status-red-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #e20c0c;
    border: 1px solid #e20c0c;
    border-radius: 4px;
  }
  .mb-half {
    margin-bottom: 5px;
  }
  .notofication-btn {
    background-color: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    padding: 12px 12px;
  }
  .slide-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    padding: 15 px;
    margin: 0;
    width: 200px;
    float: right;
  }
  .see-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    width: 125px;
  }
  .bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 135px !important;
  max-width: 350px !important;
  }
  .bs-popover-left .popover-body {
    display: flex;
    justify-content: space-between;
    border: none;
  }
  .bs-popover-bottom {
    background-color: #ffffff;
    // height: 190px;
    border: transparent;
    border: none;
    border-radius: 14px;
  }
  .popover-body {
    border: none !important;
  }
  .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #ffffff;
  }
  .vs-select__options__content {
    background-color: #ffffff !important;
  }
  .pop-btn {
    padding: 0 !important;
  }
  
  .modal-dialog {
    // max-width: 800px !important ;
  }
  .modal-body {
    padding: 0px 40px 25px 40px !important;
  }
  .form-control {
    border-radius: 14px;
  }
  .modal-content {
    border-radius: 24px;
  }
  .modal-header {
    border-radius: 24px;
  }
  textarea.form-control {
    height: 150px !important;
  }
  .modal-footer {
    display: none;
  }
  .modal-footer1 {
    display: flex;
    float: right;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.8rem 1.4rem;
  }
  </style>
  